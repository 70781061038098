import React from "react";
import Layout from "../../../layout/Layout";
import SEO from "../../../layout/Seo";
import AdminInventory from "../../../components/Admin/Inventory/AdminInventory";

export default () => (
  <Layout pageTitle="Admin Inventory">
    <SEO title="Admin Inventory" />
    <AdminInventory />
  </Layout>
);
